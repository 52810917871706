form {
  text-align: center;
  & input {
    padding: 7px 20px;
    border-radius: 15px;
    border: 1px solid #F0C3D2;
    display: block;
    width: 70%;
    font-size: 16px;
    margin: 10px auto;
  }
  & .button {
    margin: 20px auto;
    width: 50%;
  }
  & h2 {
    font-weight: normal;
    font-size: 30px;
    width: 70%;
    margin: 0 auto 20px auto;
  }
}
.main_page {
  padding: 80px;
  height: auto;
  display: grid;
  margin-top: 6vh;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;

  & div:nth-child(1) {
    text-align: left;
    & h1 {
      font-size: 40px;
      font-weight: bold;
    }
    & h1, & p {
      margin-top: 2rem;
    }
    & p {
      font-size: 18px;
    }
  }
  & div:nth-child(2) {
    & img {
      width: 90%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 1300px) {
  .main_page {
    padding: 40px;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    & div:nth-child(1) {
      text-align: left;

      & h1 {
        font-size: 30px;
        font-weight: bold;
      }

      & h1, & p {
        margin-top: 2rem;
      }

      & p {
        font-size: 15px;
      }
      & .button {
        width: 80%;
      }
    }
    & div:nth-child(2) {
      text-align: center;
      & img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 908px) {
  .main_page {
    padding: 10px;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    & div:nth-child(1) {
      text-align: center;

      & h1 {
        font-size: 35px;
      }

      & p {
        font-size: 20px;
      }
      & .button {
        width: 80%;
        margin: 30px auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .main_page {
    padding: 10px;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    & div:nth-child(1) {
      text-align: center;

      & h1 {
        font-size: 30px;
      }

      & p {
        font-size: 15px;
      }
      & .button {
        width: 60%;
        margin: 30px auto;
      }
    }
  }
  form {
    & input {
      width: 90%;
      font-size: 16px;
    }
    & .button {
      margin: 20px auto;
      width: 90%;
    }
    & h2 {
      font-size: 20px;
      width: 80%;
    }
  }
}