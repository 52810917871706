.creative_page {
  text-align: center;

  & h1 {
    margin: 8rem 0 3rem 0;
  }

   .image-box {
     width: 90%;
     margin: 0 auto;
     display: grid;
     grid-template-columns: repeat(3, 1fr);

     & img {
       border-radius: 20px;
       width: 95%;
       margin: 0.3rem auto;
       transition: .4s;

       &:hover {
         transform: scale(1.05);
       }
     }
   }
}

@media (max-width: 768px) {
  .creative_page {
    .image-box {
      grid-template-columns: repeat(2, 1fr);
    }
    & .button_1 {
      width: 80%;
    }
  }
}

@media (max-width: 480px) {
  .creative_page {
    .image-box {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}