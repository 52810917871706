.presents_page {
  width: 90%;
  margin: 3rem auto;
  & h1 {
    margin: 7rem 0 3rem 0;
    text-align: center;
  }

  & .presents_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.8rem;

    & .card {
      text-align: center;
      width: 95%;
      margin: 0 auto;
      padding: 40px 0;
      background-color: #EAEAFF;
      border-radius: 20px;
      transition: .4s;

      &:hover {
        transform: translateY(-5px);
        background-color: #dadaf8;
      }

      & p {
        margin: 1rem auto 0 auto;
        width: 70%;
      }
    }
  }
}

@media (max-width: 768px) {
  .presents_page {
    & .presents_container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}