.target_page {
  padding: 20px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;
  width: 90%;
  margin: 0 auto;

  & div {
    width: 100%;
    & div {
      width: 95%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 2.5rem 1fr;
      gap: 1rem;
      & img {
        width: 100%;
        height: auto;
      }
      & p {
        margin-left: 0.5rem;
      }
    }
  }
  & .target_image  {
    width: 90%;
    margin: 0 auto;

    & img {
      width: 100%;
      max-width: 500px;
    }
  }

  & .button {
    display: block;
    margin-top: 1rem;
    &:hover {
      color: #fff;
    }
  }

  & h1, & p {
    margin-bottom: 2.5rem;
  }
  & h1 {
    font-size: 45px;
  }
  & p {
    font-size: 18px;
  }
}

@media (max-width: 1300px) {
  .target_page {
    padding: 0px;
    height: auto;
    display: grid;
    gap: 0rem;

    & h1 {
      font-size: 35px;
    }
    & p {
      font-size: 16px;
    }
  }
}

@media (max-width: 1100px) {
  .target_page {
    padding: 0px;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.8rem;
    margin: 0 auto;
    width: 90%;

    & .button {
      margin: 2rem auto;
    }

    & div h1 {
      font-size: 35px;
      text-align: center;
    }
    & p {
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {
  .target_page {
    margin: 0 auto;
    width: 90%;
  }
  .card_container {
    grid-template-columns: repeat(1, 1fr);

    & div h1 {
      font-size: 30px;
    }
    & p {
      font-size: 12px;
    }
  }
}