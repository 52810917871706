.choose_container {
  margin: 5rem 0;
  text-align: center;

  & h1 {
    font-size: 40px;
  }
}
.card_container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8rem;

  & .card {
    background-color: #EAEAFF;
    border-radius: 20px;
    transition: .4s;
    width: 100%;
    padding: 20px;

    &:hover {
      transform: translateY(-5px);
      background-color: #dadaf8;
    }

    & p {
      margin: 0 auto;
      width: 90%;
      font-size: 18px;
    }
  }
}

@media (max-width: 1100px) {
  .card_container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.8rem;

    & .card {
      margin: 0 auto;
      width: 90%;

      & p {
        margin: 0 auto;
        width: 90%;
        font-size: 16px;
      }
    }
  }
}