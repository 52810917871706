.footer_page {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8rem;
  margin: 2rem auto;

  & div > img {
    width: 70%;
    margin: 0 auto;
  }

  & div {
    text-align: left;

    & p {
      margin: 1rem 0;
    }

    & .button {
      display: inline-block;
      width: 100%;
      margin-top: 1rem;

      &:hover {
        background-color: transparent;
        color: #f5f5f5;
      }
    }

    &:nth-child(2) {
      & p {
        text-align: left;
        padding: 0 0 0 20%;

        & .footer_link {
          color: #f5f5f5;
        }
      }
    }
  }
}

.separator {
  margin: 1rem 0;
  display: block;
  height: 10px;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}

.copyright {
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer_page {
    grid-template-columns: repeat(2, 1fr);

    & div:nth-child(1) {
      text-align: center;
      grid-column: 1 / 4;
    }
    & div:nth-child(2) {
      & p:nth-child(1) {
        margin-top: 20%;
      }
    }
  }
}