*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #fff;
  color: #000;
  font-family: Roboto, sans-serif;
  overflow-y: scroll;
}
a, ul, li {
  text-decoration: none;
}
@media (min-width: 2560px) {
  html {
    zoom: 1.3;
  }
}
@media (min-width: 3860px) {
  html {
    zoom: 1.5;
  }
}
.wrap {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.container {
  color: #fff;
  margin-top: 8rem;
  background-color: #5D5FEF;
  padding: 4rem 0.5rem;
  width: 100%;
}
.button_1 {
  margin: 3rem auto;
  background-color: transparent;
  width: 30%;
  padding: 7px;
  color: #000;
  border-radius: 20px;
  cursor: pointer;
  border: 3px solid #5D5FEF;
  transition: .4s;

  & .button_1_text {
    transition: .4s;
  }

  &:hover {
    background-color: #5D5FEF;
    .button_1_text {
      color: #F5F5F5;
    }
  }
}
.button {
  margin-top: 3rem;
  background-color: #E62E52;
  width: 70%;
  padding: 7px;
  text-align: center;
  color: #F5F5F5;
  border-radius: 20px;
  cursor: pointer;
  border: 3px solid #E62E52;
  transition: .4s;

  &:hover {
    background-color: #F5F5F5;
    color: #000;
  }

  & a {
    text-align: center;
    color: #F5F5F5;
    text-decoration: none;
  }
}