.team_page {
    padding: 20px;
    height: auto;
    width: 90%;
    margin: 0 auto;

    & h1 {
        text-align: center;
        margin: 7rem 0 3rem;
    }

    & .team_card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.8rem;
        margin: 2rem auto;

        & img {
            width: 70%;
            margin: 0 auto;
        }
        & div {
            & h2 {
                margin: 20% 0 1rem;
            }
            & p {
                margin-bottom: 0.2rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .team_page {
        & .team_card {
            grid-template-columns: repeat(1, 1fr);
            & img {
                width: 90%;
            }
            & div {
              & h2 {
                  margin: 1rem 0;
              }
            }
        }
    }
}