.gallery_page {
  column-count: 3;
  column-gap: 1rem;
  margin-top: 7rem;

  & a {
    width: 100%;

    & img {
      width: 100%;
      border-radius: 50px;
      display: block;
      padding-top: 1rem;
      cursor: pointer;
      transition: .4s;

      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}

@media (max-width: 768px) {
  .gallery_page {
    column-count: 2;

    & a {
      width: 100%;

      & img {
        width: 95%;
        margin: 0 auto;
        border-radius: 35px;
      }
    }
  }
}

@media (max-width: 768px) {
  .gallery_page {
    column-count: 1;

    & a {

      & img {
        width: 95%;
        margin: 0 auto;
        border-radius: 25px;
      }
    }
  }
}