.documents_page {
  & h1 {
    margin: 8rem 0 1rem 0;
  }

  & .block {
    padding: 1rem;

    & h1 {
      font-size: 36px;
    }
    & h2 {
      font-size: 24px;
      font-weight: normal;
    }
    & a:nth-child(2) {
      margin-top: 1.2rem;
    }
    & a {
      display: block;
      margin-bottom: 0.5rem;
      color: #000;
      text-decoration: none;
      cursor: pointer;
      transition: opacity 400ms;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

    }
    & p {
      margin-top: 1rem;
    }
  }
}