.creator_page {
  & h1 {
    text-align: center;
    margin: 7rem 0 3rem;
  }

  & .creator_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.8rem;
    width: 90%;
    margin: 3rem auto;

    & img {
      margin: 0 auto;
      width: 80%;
    }
    & div {
      & h2 {
        margin: 6rem 0 1rem;
      }
      & p {
        margin: 1rem 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .creator_page {
    & .creator_container {
      grid-template-columns: repeat(1, 1fr);

      & div {
        & h2 {
          margin: 1rem 0;
        }
      }
    }
  }
}