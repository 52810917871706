.directions_container {
  margin-top: 5rem;
  text-align: center;

  & h1 {
    font-size: 40px;
  }
}

.directions_cards_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.8rem;
  margin: 3rem auto;
  width: 90%;

  & .card {
    padding: 15px;
    background-color: #EAEAFF;
    border-radius: 20px;
    width: 100%;
    transition: .4s;

    &:hover {
      transform: translateY(-5px);
      background-color: #dadaf8;
    }

    & img {
      width: 100%;
    }

    & p, & h2 {
      padding-top: 5px;
    }

    & h2 {
      font-size: 22px;
      font-weight: normal;
    }
  }
}

@media (max-width: 1100px) {
  .directions_cards_container {
    width: 90%;
    margin: 3rem auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .directions_cards_container {
    width: 90%;
    margin: 3rem auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 320px) {

}