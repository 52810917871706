.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 106vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in-out;
}

.modal {
  background-color: #fff;
  color: #000;
  width: 70%;
  max-width: 500px;
  max-height: auto;
  padding: 40px 30px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;

  img {
    border-radius: 5px;
    width: 100%;
  }

  & p {
    font-size: 12px;
    margin: 0 auto;
    text-align: center;
    opacity: 0.5;
    width: 80%;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 24px;
    width: 24px;
    opacity: 0.3;
    cursor: pointer;
    transition: .4s;

    &:hover {
      opacity: 1;
      transform: rotate(180deg);
    }
  }
}

.overlay.animated {
  opacity: 0;
  visibility: hidden;

  .modal {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -30%);
    transition-delay: 0.2s;
  }

  &.show {
    opacity: 1;
    visibility: visible;

    .modal {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%);
    }
  }
}

.success-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  animation: success-show 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25);

  img {
    width: 120px;
    height: 120px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  p {
    text-align: center;
    margin: 30px 0;
  }

  button {
    width: 200px;
  }
}

@keyframes success-show {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
