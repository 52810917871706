.contacts_page {
  text-align: center;

  & h1 {
    margin: 8rem 0 3rem 0;
  }

  & .contacts_container {
    padding: 20px;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.8rem;
    width: 100%;

    & img {
      width: 90%;
      transition: .4s;
      margin: 0 auto;

      &:hover {
        transform: translateY(-5px);
      }
    }

    & div {
      text-align: left;
      padding: 5rem;
      background-color: #EAEAFF;
      border-radius: 20px;
      width: 90%;
      transition: .4s;
      margin: 0 auto;

      &:hover {
        transform: translateY(-5px);
        background-color: #dadaf8;
      }

      & h1 {
        margin: 0;
        font-weight: normal;
        font-size: 24px;
      }

      & p:nth-child(2), & p:nth-child(3), & p:nth-child(4) {
        margin-top: 1rem;
      }
      & p:nth-child(5),& p:nth-child(6) {
        margin-top: 0.2rem;
      }
    }
  }
}

.map {
  position:relative;
  overflow:hidden;
  margin-top: 0rem;

  & a:nth-child(1) {
    color:#000;
    font-size:12px;
    position:absolute;
    top:0px;
    z-index: 1000;
  }
  & a:nth-child(2) {
    color:#000;
    font-size:12px;
    position:absolute;
    top:14px;
  }
  & iframe {
    position:relative;
  }
}

@media (max-width: 768px) {
  .contacts_page {
    & .contacts_container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .map {
    margin-top: 0;
  }
}